import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, HelpCircle, MessageSquare, Calendar, ArrowRight, Clock, ChevronDown } from 'lucide-react';
import Navbar from '../components/landing_page/Navbar';
import Footer from '../components/headers/Footer';

const FAQItem = ({ question, answer, isOpen, onToggle }) => {
  return (
    <motion.div
      className="border border-white/10 rounded-lg overflow-hidden bg-white/5 backdrop-blur-sm"
      initial={false}
    >
      <button
        onClick={onToggle}
        className="w-full px-6 py-4 flex items-center justify-between text-left"
      >
        <span className="text-white font-inter hover:text-orange-400 transition-colors">
          {question}
        </span>
        <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.2 }}>
          <ChevronDown className="w-5 h-5 text-white/60" />
        </motion.div>
      </button>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className="overflow-hidden"
          >
            <div className="px-6 pb-4 text-white/60 font-inter">{answer}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const DemoBox = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="w-full"
    >
      <div className="relative bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-sm border border-white/20 rounded-xl p-8 overflow-hidden">
        {/* Decorative Elements */}
        <div className="absolute top-0 right-0 w-64 h-64 bg-orange-500/10 rounded-full blur-3xl -mr-32 -mt-32" />
        <div className="absolute bottom-0 left-0 w-64 h-64 bg-orange-500/5 rounded-full blur-3xl -ml-32 -mb-32" />
        
        {/* Content */}
        <div className="relative z-10">
          <div className="flex items-center gap-3 mb-6">
            <div className="flex items-center bg-orange-500/20 rounded-full p-2">
              <Calendar className="w-4 h-4 text-orange-400 mr-2" />
              <span className="text-sm text-orange-400 font-medium">Free 30-min Session</span>
            </div>
          </div>

          <h2 className="text-3xl font-semibold text-white mb-4 font-inter">
            See How MailBandit Can Transform Your Email Marketing
          </h2>
          
          <ul className="space-y-3 mb-8">
            {[
              'Live demonstration of our AI optimization engine',
              'Personalized strategy discussion for your business',
              'ROI calculation & implementation timeline',
              'Q&A session with our email experts'
            ].map((item, index) => (
              <li key={index} className="flex items-center text-white/70 font-inter">
                <div className="w-1.5 h-1.5 bg-orange-400 rounded-full mr-3" />
                {item}
              </li>
            ))}
          </ul>

          <div className="flex flex-col space-y-4">
            <motion.a
              href="https://calendly.com/rayhan-mailbandit/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="group flex items-center justify-center px-6 py-4 bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700 text-white rounded-xl font-inter text-lg transition-all"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Schedule Your Demo
              <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
            </motion.a>

            <div className="flex items-center justify-center text-white/40 text-sm font-inter">
              <Clock className="w-4 h-4 mr-2" />
              <span>30-minute session • No commitment required</span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Contact = () => {
  const [openFaq, setOpenFaq] = useState(null);

  const contactChannels = [
    {
      email: 'sales@mailbandit.ai',
      title: 'Sales Inquiries',
      description:
        'Looking to scale your email campaigns? Our sales team can help you find the right plan and show you how MailBandit can optimize your email performance.',
      icon: <Mail className="w-6 h-6" />,
    },
    {
      email: 'help@mailbandit.ai',
      title: 'Technical Support',
      description:
        'Need help with Klaviyo integration or having issues with A/B testing setup? Our technical team is here to assist.',
      icon: <HelpCircle className="w-6 h-6" />,
    },
    {
      email: 'feedback@mailbandit.ai',
      title: 'Product Feedback',
      description:
        'Using MailBandit and have ideas for improvement? We love hearing from our users about new features and enhancements.',
      icon: <MessageSquare className="w-6 h-6" />,
    },
  ];

  const faqs = [
    {
      question: "How does MailBandit's AI optimize email campaigns?",
      answer:
        'MailBandit uses machine learning algorithms to analyze email performance metrics in real-time. Our system automatically adjusts allocation across each variant to ensure the best email is being sent to your customers.',
    },
    {
      question: 'Can I use MailBandit with my existing Klaviyo setup?',
      answer:
        'Yes! MailBandit integrates seamlessly with Klaviyo. Our platform sits on top of your existing Klaviyo workflows, adding advanced A/B testing and AI optimization without disrupting your current setup. The integration process typically takes less than 5 minutes.',
    },
    {
      question: 'What kind of performance improvements can I expect?',
      answer:
        'Our customers typically see a 15-30% increase in email engagement rates within the first month. This includes improvements in open rates, click-through rates, and ultimately, conversion rates. Results can vary based on your current baseline and industry.',
    },
    {
      question: 'How many email variants can I test simultaneously?',
      answer:
        "MailBandit supports as many variants as you like but we recommend up to 4 variants in a single test for best results. If you're looking to test more, get in touch and we can help setup!",
    },
    {
      question: 'Do you offer a free trial?',
      answer:
        'Yes, we offer a 30-day free trial with full access to all features. No credit card is required to start. You can test all our features including AI optimization, multi-variant testing, and real-time analytics during the trial period.',
    },
    {
      question: 'What makes MailBandit different from basic A/B testing?',
      answer:
        'Unlike traditional A/B testing, MailBandit uses dynamic traffic allocation to optimize in real-time. Our AI considers multiple variables simultaneously, adapts to changing performance patterns, and automatically implements winning variants - all without manual intervention.',
    },
  ];

  return (
    <div className="min-h-screen bg-black">
      <Navbar />

      {/* Hero Section */}
      <motion.div
        className="relative min-h-[40vh] flex items-center justify-center overflow-hidden pt-20"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Gradient Background */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-orange-500/20 via-black to-black" />
          <div className="absolute top-0 left-1/4 w-96 h-96 bg-orange-500/20 rounded-full blur-3xl" />
          <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl" />
        </div>

        <div className="relative z-10 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            className="text-center"
          >
            <motion.div
              className="inline-flex items-center bg-white/10 backdrop-blur-md rounded-full px-5 py-2.5 border border-white/10 mb-6"
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
            >
              <div className="w-2 h-2 bg-green-700 rounded-full mr-3"></div>
              <span className="text-white/90 text-sm md:text-base font-inter">
                24/7 Support Available
              </span>
            </motion.div>

            <h1 className="text-4xl md:text-5xl lg:text-6xl font-semibold text-white mb-6 font-inter leading-tight">
              Get in Touch
            </h1>

            <p className="text-lg md:text-xl text-white/80 max-w-2xl mx-auto font-inter leading-relaxed mb-8">
              Have questions about MailBandit? We're here to help you optimize your email marketing.
            </p>
          </motion.div>
        </div>
      </motion.div>

      {/* Two-Row Contact Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col space-y-8">
          {/* Schedule Demo Box */}
          <DemoBox />

          {/* Contact Boxes Row */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-3 gap-6"
          >
            {contactChannels.map((channel, index) => (
              <motion.a
                key={channel.email}
                href={`mailto:${channel.email}`}
                className="group block bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 hover:bg-white/10 transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -2 }}
              >
                <div className="flex items-start gap-4">
                  <div className="w-12 h-12 rounded-full bg-orange-500/20 flex items-center justify-center text-orange-400">
                    {channel.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white mb-2 font-inter group-hover:text-orange-400 transition-colors">
                      {channel.title}
                    </h3>
                    <p className="text-white/60 mb-4 font-inter">{channel.description}</p>
                    <p className="text-orange-400 font-mono">{channel.email}</p>
                  </div>
                </div>
              </motion.a>
            ))}
          </motion.div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-semibold text-white mb-6 font-inter">
            Common Questions
          </h2>
          <p className="text-white/60 font-inter">
            Everything you need to know about MailBandit's AI-powered email optimization.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
          className="space-y-4"
        >
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openFaq === index}
              onToggle={() => setOpenFaq(openFaq === index ? null : index)}
            />
          ))}
        </motion.div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;