import {HighlightedText} from 'components/items/Headings';
import {images} from 'constants/images';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

const Product = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full relative">
      {/* Background color divider */}
      <div className="absolute inset-0">
        <motion.div
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1}}
          viewport={{once: true}}
          className="h-[calc(50%)] bg-white"
        />
        <motion.div
          initial={{opacity: 0}}
          whileInView={{opacity: 1}}
          transition={{duration: 1, delay: 0.2}}
          viewport={{once: true}}
          className="h-[calc(50%)] bg-[#202122]"
        />
      </div>

      {/* Content */}
      <div className="relative">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 py-24">
          {/* Text Content */}
          <div className="text-center max-w-3xl mx-auto mb-16">
            <motion.h2
              initial={{opacity: 0, y: 30}}
              whileInView={{opacity: 1, y: 0}}
              viewport={{once: true}}
              transition={{
                duration: 0.8,
                type: 'spring',
                bounce: 0.4,
              }}
              className="text-4xl md:text-5xl font-semibold font-inter  pt-8 leading-tight"
            >
              Maximise <HighlightedText> Revenue + Clicks </HighlightedText>With Realtime AI A/B
              Testing
            </motion.h2>
            <motion.p
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: 1, y: 0}}
              viewport={{once: true}}
              transition={{
                duration: 0.8,
                delay: 0.2,
                type: 'spring',
                bounce: 0.4,
              }}
              className="text-black text-lg md:text-xl font-light font-inter leading-relaxed py-8"
            >
              Your personal AI assistant, monitoring your A/B test and improving to your customers
              liking.
            </motion.p>
          </div>

          {/* Product Image Container */}
          <motion.div
            className="relative mx-auto max-w-5xl mb-12"
            initial={{opacity: 0, y: 40}}
            whileInView={{opacity: 1, y: 0}}
            viewport={{once: true, margin: '-100px'}}
            transition={{
              duration: 1,
              delay: 0.3,
              type: 'spring',
              bounce: 0.3,
            }}
          >
            <motion.div
              className="rounded-2xl overflow-hidden border border-white/10 shadow-2xl"
              whileHover={{
                scale: 1.02,
                transition: {duration: 0.3},
              }}
            >
              <img src={images.mbmain} alt="MailBandit Dashboard" className="w-full h-auto" />
            </motion.div>
          </motion.div>

          {/* CTA Button */}
          <motion.div
            className="text-center"
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: 1, y: 0}}
            viewport={{once: true}}
            transition={{
              duration: 0.8,
              delay: 0.5,
              type: 'spring',
              bounce: 0.4,
            }}
          >
            <motion.button
              className="inline-flex items-center justify-center px-8 py-4 bg-orange-500 hover:bg-orange-600 text-white rounded-full font-inter text-lg"
              whileHover={{scale: 1.05}}
              whileTap={{scale: 0.98}}
              transition={{
                type: 'spring',
                stiffness: 400,
                damping: 17,
              }}
              onClick={() => navigate('/signup')}
            >
              Start Optimising Your Campaigns
            </motion.button>
            <motion.div
              className="mt-4 space-y-2"
              initial={{opacity: 0}}
              whileInView={{opacity: 1}}
              viewport={{once: true}}
              transition={{duration: 0.6, delay: 0.7}}
            >
              <p className="text-white font-inter">
                Start your 30 day free trial now. No Credit Card Required.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Product;
