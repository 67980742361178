import {functions} from '../../../configuration/firebase/Firebase';
import {httpsCallable} from 'firebase/functions';

// New function to check if the account is connected
export async function checkAccountConnection(teamId) {
  const isAccountConnected = httpsCallable(functions, 'is_account_connected');

  try {
    const result = await isAccountConnected({teamId: teamId});
    return result.data.success === true; // Returns true if connected
  } catch (error) {
    console.error('Error checking account connection:', error);
    return false; // Returns false if there's an error
  }
}

// New function to check if the account is connected
export async function getKlaviyoOAuthUrl(teamId) {
  const getAuthUrlFunction = httpsCallable(functions, 'get_auth_url');

  try {
    const result = await getAuthUrlFunction({teamId: teamId});
    return await result.data.url; // Returns true if connected
  } catch (error) {
    console.error('Error checking account connection:', error);
    return false; // Returns false if there's an error
  }
}

export async function handleOAuthCallback(code, state) {
  const handleCallback = httpsCallable(functions, 'handle_oauth_callback');

  try {
    const result = await handleCallback({code, state});

    return result.data; // Return the result data
  } catch (error) {
    console.error('Error handling OAuth callback:', error);
    throw error; // Rethrow the error for handling in the calling function
  }
}

export const handleDisconnectKlaviyo = async teamId => {
  const revokeToken = httpsCallable(functions, 'revoke_refresh_token');
  try {
    const result = await revokeToken({teamId});
    return result.data; // Assuming the function returns { success: true/false }
  } catch (error) {
    console.error('Error revoking refresh token:', error);
    throw error; // Rethrow the error for handling in the calling function
  }
};
