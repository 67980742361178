import {HighlightedText} from 'components/items/Headings';

// src/components/Closing.jsx
const Closing = () => {
  return (
    <div className="w-full bg-white py-20">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          {/* Left Column */}
          <div>
            <h2 className="text-4xl md:text-5xl font-semibold font-inter text-black mb-6 leading-tight">
              A/B testing with <HighlightedText>AI precision</HighlightedText>
            </h2>

            <p className="text-xl md:text-2xl font-light font-inter text-black/80">
              Deliver the right message at the right moment
            </p>
          </div>

          {/* Right Column */}
          <div className="flex flex-col space-y-8">
            <p className="text-black/70 text-lg font-light font-inter leading-relaxed">
              Start optimizing your email campaigns today with our AI-powered platform. No credit
              card required, no commitment needed.
            </p>

            <div>
              <a
                href="https://calendly.com/rayhan-mailbandit/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="inline-flex items-center justify-center px-8 py-4 bg-black hover:bg-orange-600 text-white rounded-full font-inter text-lg transition-all hover:scale-105">
                  Schedule a demo today
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Closing;
