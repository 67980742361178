import {initializeApp} from 'firebase/app';
import {connectFirestoreEmulator, getFirestore} from 'firebase/firestore';
import {getAnalytics} from 'firebase/analytics';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';

const firebaseConfigs = {
  production: {
    apiKey: 'AIzaSyCvlJUzszbsgf-D3ObXHggDnB4euO4AKLI',
    authDomain: 'mailbandit-1d9cc.firebaseapp.com',
    databaseURL: 'https://mailbandit-1d9cc-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'mailbandit-1d9cc',
    storageBucket: 'mailbandit-1d9cc.appspot.com',
    messagingSenderId: '359639967150',
    appId: '1:359639967150:web:01369499cc3637933c943f',
    measurementId: 'G-L3JZ0D6GK7',
  },
  development: {
    apiKey: 'AIzaSyAFyCCGGvEKXLkUg40Ew5t0V4hBbFZ7sZQ',
    authDomain: 'mailbandit-dev.firebaseapp.com',
    projectId: 'mailbandit-dev',
    storageBucket: 'mailbandit-dev.firebasestorage.app',
    messagingSenderId: '549823553816',
    appId: '1:549823553816:web:ec8488635ce9d9a45dfe6d',
  },
};

export const environment = 'production';
const firebaseConfig = firebaseConfigs[environment];
export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const functions = getFunctions(firebaseApp);

if (process.env.NODE_ENV !== 'production') {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}
