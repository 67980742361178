import React, {useState, useEffect, useContext} from 'react';
import tw, {styled} from 'twin.macro';
import {TourContext} from '../helpers/tour/TourContext';

import Header from 'components/headers/light';
import {PageContainer, ContentWithPaddingXl} from 'components/items/Layouts';
import {GreenButton, OptionsButton, PrimaryButton, RedButton} from 'components/items/Buttons';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {DashboardHeading, HighlightedText, SmallSectionHeading} from 'components/items/Headings';

import {IsLoggedIn} from 'helpers/api/auth/auth';
import {getTeamInfo} from 'helpers/api/firestore/Teams';
import {
  checkSubscriptionStatus,
  createStripeCheckoutSession,
  getSubscriptionStatusMessage,
  manageTeamSubscription,
  updateTeamSubscription,
  tiers,
} from 'helpers/api/functions/Stripe';
import SubscriptionStatus from 'components/items/SubscriptionStatus';
import {CurrencyToggleComponent} from 'components/items/CurrencyToggle';
import {Modal} from 'components/items/Modals';
import {handleDisconnectKlaviyo, checkAccountConnection} from 'helpers/api/functions/Oauth';

const SubscriptionCard = styled.div`
  ${tw`
    border rounded-lg p-6 
    shadow-md 
    flex flex-col 
    justify-between 
    hover:shadow-xl 
    transition-all 
    duration-300
    mb-6
  `}
`;

const DisconnectButton = tw.button`mt-4 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors disabled:bg-gray-500`;

export default () => {
  const [user] = IsLoggedIn();
  const [loading, setLoading] = useState(true);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currency, setCurrency] = useState('usd');
  const {run, stepIndex, setRun, setStepIndex} = useContext(TourContext);
  const [continueTour, setContinueTour] = useState(false);
  const [error, setError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const fetchTeamAndSubscriptionData = async () => {
      try {
        const team = await getTeamInfo(user.uid);
        setCurrentTeam(team);

        const isAccountConnected = await checkAccountConnection(team.id);
        setIsConnected(isAccountConnected);

        const subscriptionResult = await checkSubscriptionStatus(team.id);
        setCurrentSubscription(subscriptionResult);

        // Determine currency based on current subscription
        if (subscriptionResult.planType?.includes('gbp')) {
          setCurrency('gbp');
        }

        setLoading(false);
      } catch (error) {
        setError('Failed to fetch subscription data. Please try again or contact support.');
        setShowErrorModal(true);
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    if (user) {
      fetchTeamAndSubscriptionData();
    }
  }, [user]);

  useEffect(() => {
    if (run && loading) {
      setRun(false);
      setContinueTour(true);
    }
    if (continueTour && !loading) {
      setContinueTour(false);
      setRun(true);
    }
  }, [loading]);

  const handleSubscriptionUpgrade = async tierId => {
    try {
      setLoading(true);
      // Check if user has an active subscription that's not a trial
      if (currentSubscription?.status === 'active' && !currentSubscription?.trial) {
        await updateTeamSubscription(tierId, currentTeam.id);
      } else {
        await createStripeCheckoutSession(tierId, currentTeam.id);
      }
    } catch (error) {
      console.error('Subscription upgrade error:', error);
      setError('Failed to process subscription upgrade. Please try again or contact support.');
      setShowErrorModal(true);
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setLoading(true);
      await manageTeamSubscription(currentTeam.id);
    } catch (error) {
      console.error('Subscription management error:', error);
      setError('Failed to access subscription management. Please try again or contact support.');
      setShowErrorModal(true);
      setLoading(false);
    }
  };

  const handleDisconnectClick = () => {
    setShowDisconnectModal(true);
  };

  const confirmDisconnect = async () => {
    setShowDisconnectModal(false);
    setLoading(true);
    try {
      const result = await handleDisconnectKlaviyo(currentTeam.id);
      if (result.success) {
        setShowSuccessModal(true);
        setLoading(false);
      } else {
        setError('Failed to disconnect your Klaviyo account. Please try again.');
        setShowErrorModal(true);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error disconnecting Klaviyo account:', error);
      setError('An error occurred while disconnecting your account.');
      setShowErrorModal(true);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <ContentWithPaddingXl>
        <div className="w-full justify-items-center">
          <DashboardHeading>My Account</DashboardHeading>
        </div>
        <SubscriptionStatus
          status={currentSubscription.status}
          message={getSubscriptionStatusMessage(
            currentSubscription.status,
            currentSubscription?.trialEnd
          )}
        />
        <div className="md:flex md:justify-between">
          <SmallSectionHeading>
            Brand: <HighlightedText>{currentTeam.name}</HighlightedText>
          </SmallSectionHeading>
          <SmallSectionHeading>
            Your Current Plan:{' '}
            <HighlightedText>{currentSubscription?.planType || 'Unsubscribed'}</HighlightedText>
          </SmallSectionHeading>
        </div>

        <div className="flex justify-center">
          <CurrencyToggleComponent currency={currency} onCurrencyChange={setCurrency} />
        </div>

        <div className="grid md:grid-cols-3 gap-6" id={'setup-subscription-plan'}>
          {tiers[currency].map(tier => (
            <SubscriptionCard key={tier.id}>
              <div>
                <h3 className="text-xl font-bold mb-4">{tier.name}</h3>
                <p className="text-2xl font-semibold mb-4">
                  {tier.priceMonthly} {tier.priceMonthly !== 'Contact Us' && '/month'}
                </p>
                <ul className="space-y-2 mb-4">
                  {tier.features.map(feature => (
                    <li key={feature} className="flex items-center">
                      <span className="mr-2">✓</span> {feature}
                    </li>
                  ))}
                </ul>
              </div>
              {tier.priceMonthly !== 'Contact Us' && (
                <>
                  {currentSubscription?.planType === tier.id ? (
                    <GreenButton onClick={() => handleSubscriptionUpgrade(tier.id)} disabled={true}>
                      Current plan
                    </GreenButton>
                  ) : (
                    <PrimaryButton onClick={() => handleSubscriptionUpgrade(tier.id)}>
                      Select
                    </PrimaryButton>
                  )}
                </>
              )}
            </SubscriptionCard>
          ))}
        </div>
        {currentSubscription?.status === 'active' && !currentSubscription?.trial && (
          <div className="text-center mb-6">
            <OptionsButton onClick={handleManageSubscription}>Manage My Subscription</OptionsButton>
          </div>
        )}

        {/* Disconnect Klaviyo Account Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg mt-10">
          <h3 className="text-lg font-semibold">Disconnect Klaviyo Account</h3>
          <p className="text-sm text-gray-600">
            Disconnecting your Klaviyo account will stop any running experiments, which may affect
            your account.
          </p>
          <DisconnectButton onClick={handleDisconnectClick} disabled={isConnected == false}>
            Disconnect Klaviyo Account
          </DisconnectButton>
        </div>

        {/* Confirmation Modal */}
        <Modal
          isOpen={showDisconnectModal}
          modalHeading="Confirm Disconnect"
          modalBody={
            'Are you sure you want to disconnect your Klaviyo account? This will stop any running experiments.'
          }
          modalButtons={[
            {
              text: 'Cancel',
              action: () => {
                setShowDisconnectModal(false);
              },
              buttonTag: OptionsButton,
            },
            {
              text: 'Disconnect',
              action: () => {
                confirmDisconnect();
              },
              buttonTag: RedButton,
            },
          ]}
        />

        {/* Success Modal */}
        <Modal
          isOpen={showSuccessModal}
          modalHeading="Account Disconnected"
          modalBody={
            <>
              <p>Your Klaviyo account has been successfully disconnected - </p>
              <p>Any running experiments should remove Mailbandit functionality.</p>
            </>
          }
          modalButtons={[
            {
              text: 'Okay',
              action: () => {
                setShowSuccessModal(false);
              },
              buttonTag: OptionsButton,
            },
          ]}
        />

        {/* Error Modal */}
        <Modal
          isOpen={showErrorModal}
          modalHeading="Error"
          modalBody={<p>{error}</p>}
          modalButtons={[
            {
              text: 'Okay',
              action: () => {
                setShowErrorModal(false);
              },
              buttonTag: OptionsButton,
            },
          ]}
        />
      </ContentWithPaddingXl>
    </PageContainer>
  );
};
