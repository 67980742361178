import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import tw from 'twin.macro';

const Input = tw.input`bg-white/10 rounded-full px-4 py-2 border border-white/10 focus:outline-none focus:border-white/30 transition-colors font-inter`;

const Footer = () => {
  return (
    <footer className="bg-black/90 text-white py-16">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Brand Column */}
          <div className="col-span-1">
            <h2 className="text-2xl font-semibold font-inter mb-4">MailBandit</h2>
            <p className="text-white/60 font-inter">
              AI-powered email marketing optimization for modern businesses.
            </p>
          </div>

          {/* Pages */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4 font-inter">Pages</h3>
            <ul className="space-y-3">
              {[
                {name: 'Home', path: '/'},
                // { name: 'About', path: '/about' },
                {name: 'Pricing', path: '/pricing'},
                {name: 'Contact', path: '/contact'},
              ].map(item => (
                <li key={item.name}>
                  <Link
                    to={item.path}
                    className="text-white/60 hover:text-white transition-colors font-inter"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Account */}
          <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4 font-inter">Account</h3>
            <div className="flex flex-col space-y-3 md:max-w-fit">
              <Link
                to="/login"
                className="inline-flex justify-center items-center w-full md:w-auto px-6 py-2.5 text-white text-sm font-inter rounded-full border border-orange-500 hover:bg-white hover:text-orange-500 hover:border-transparent transition-all duration-200"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="inline-flex justify-center items-center w-full md:w-auto px-6 py-2.5 text-sm font-inter rounded-full bg-gradient-to-r from-orange-500 to-orange-600 text-white hover:from-orange-600 hover:to-orange-700 transition-all duration-200 shadow-lg hover:shadow-orange-500/25"
              >
                Sign up
              </Link>
            </div>
          </div>

          {/* Newsletter - Commented out for now */}
          {/* <div className="col-span-1">
            <h3 className="text-lg font-semibold mb-4 font-inter">Stay Updated</h3>
            <div className="flex space-x-2">
              <Input type="email" placeholder="Enter your email" />
              <button className="bg-orange-500 hover:bg-orange-600 rounded-full px-6 py-2 transition-colors font-inter">
                Subscribe
              </button>
            </div>
          </div> */}
        </div>

        {/* Bottom Bar */}
        <div className="mt-16 pt-8 border-t border-white/10">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-white/60 text-sm font-inter">
              © 2023 MailBandit. All rights reserved.
            </p>
            {/* <div className="flex space-x-6 mt-4 md:mt-0">
              {['Privacy', 'Terms', 'Cookies'].map(item => (
                <a
                  key={item}
                  href="#"
                  className="text-white/60 hover:text-white text-sm transition-colors font-inter"
                >
                  {item}
                </a>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
