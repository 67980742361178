import React from 'react';
import tw from 'twin.macro';

const ResponsiveHeading = tw.h2`text-2xl sm:text-3xl font-semibold mb-4 text-center`;
const ResponsiveParagraph = tw.p`text-base sm:text-lg mb-6 text-center`;
const ResponsiveInput = tw.input`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-primary-500 text-sm sm:text-base`;
const FlexResponsiveContainer = tw.div`flex justify-center`;
const ResponsiveButton = tw.button`px-6 py-3 bg-orange-500 text-white rounded-lg font-medium hover:bg-orange-600 transition-colors disabled:bg-gray-500`;

const BrandSetupStep = ({newTeamName, setNewTeamName, teamCreationError, handleCreateTeam}) => {
  return (
    <div className="space-y-4">
      <ResponsiveHeading>Give your account a name</ResponsiveHeading>
      <ResponsiveParagraph>
        This helps where you have multiple users or use an agency to setup your ads. <br /> PS:
        Usually your brand name.
      </ResponsiveParagraph>
      <ResponsiveInput
        type="text"
        value={newTeamName}
        onChange={e => {
          setNewTeamName(e.target.value);
        }}
        placeholder="Enter account name"
      />
      {teamCreationError && <p tw="text-red-500 text-sm text-center">{teamCreationError}</p>}
      <FlexResponsiveContainer>
        <ResponsiveButton onClick={handleCreateTeam} disabled={!newTeamName.trim()}>
          Create Account
        </ResponsiveButton>
      </FlexResponsiveContainer>
    </div>
  );
};

export default BrandSetupStep;
