import {useState, useEffect} from 'react';
import {images} from 'constants/images';
import {motion} from 'framer-motion';
import {ChevronLeft, ChevronRight} from 'lucide-react';
import {HighlightedText} from 'components/items/Headings';

const Tests = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const emailSets = [
    {
      id: 1,
      emails: [images.dress1, images.dress2],
    },
  ];

  const mobileEmails = emailSets[0].emails;

  const handleNext = () => {
    if (window.innerWidth >= 768) {
      setCurrentSlide(prev => (prev + 1) % emailSets.length);
    } else {
      setCurrentSlide(prev => (prev + 1) % mobileEmails.length);
    }
  };

  const handlePrev = () => {
    if (window.innerWidth >= 768) {
      setCurrentSlide(prev => (prev - 1 + emailSets.length) % emailSets.length);
    } else {
      setCurrentSlide(prev => (prev - 1 + mobileEmails.length) % mobileEmails.length);
    }
  };

  const handleDragEnd = (event, info) => {
    if (info.offset.x > 100) {
      handlePrev();
    } else if (info.offset.x < -100) {
      handleNext();
    }
  };

  return (
    <motion.div
      className="w-full bg-white py-24"
      initial={{opacity: 0}}
      whileInView={{opacity: 1}}
      viewport={{once: true}}
      transition={{duration: 0.8}}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.h2
            className="text-3xl md:text-5xl font-semibold font-inter text-black mb-6 leading-tight"
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: 1, y: 0}}
            viewport={{once: true}}
            transition={{duration: 0.8, type: 'spring'}}
          >
            RoseWaterHouse add an <br />
            <motion.span
              initial={{opacity: 0, x: -10}}
              whileInView={{opacity: 1, x: 0}}
              viewport={{once: true}}
              transition={{duration: 0.8, delay: 0.2}}
            >
              <HighlightedText>additional 20% AOV</HighlightedText>
            </motion.span>
            <br />
            with MailBandit
          </motion.h2>
          <motion.p
            className="text-black text-lg md:text-xl font-light font-inter leading-relaxed"
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: 1, y: 0}}
            viewport={{once: true}}
            transition={{duration: 0.8, delay: 0.2}}
          >
            AI-Powered A/B(C/D/E/F/G) testing on autopilot. Let our AI dynamically allocate traffic
            to the best-performing variants, making you more money while saving you time.
          </motion.p>
        </div>

        {/* Desktop Carousel */}
        <div className="relative max-w-6xl mx-auto mb-8 hidden md:block">
          <div className="px-12">
            <div className="overflow-hidden">
              <motion.div
                className="flex"
                animate={{x: `-${currentSlide * 100}%`}}
                transition={{type: 'spring', stiffness: 300, damping: 30}}
              >
                {emailSets.map((set, setIndex) => (
                  <div key={set.id} className="w-full flex-shrink-0">
                    <div className="grid grid-cols-2 gap-3 justify-items-center">
                      {set.emails.map((email, emailIndex) => (
                        <motion.div
                          key={emailIndex}
                          className="relative w-full max-w-sm overflow-hidden "
                          initial={{opacity: 0, y: 20}}
                          whileInView={{opacity: 1, y: 0}}
                          viewport={{once: true}}
                          transition={{
                            duration: 0.5,
                            delay: emailIndex * 0.1,
                            type: 'spring',
                          }}
                          whileHover={{
                            scale: 1.05,
                            transition: {duration: 0.2},
                          }}
                        >
                          <img
                            src={email}
                            alt={`Email example ${setIndex * 2 + emailIndex + 1}`}
                            className="w-full h-auto shadow-lg rounded-lg"
                          />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </div>

        {/* Mobile Carousel */}
        <div className="relative max-w-[300px] mx-auto mb-4 md:hidden">
          <div className="overflow-hidden">
            <motion.div
              className="flex touch-pan-x"
              animate={{x: `-${currentSlide * 100}%`}}
              transition={{type: 'spring', stiffness: 300, damping: 30}}
              drag="x"
              dragConstraints={{left: 0, right: 0}}
              dragElastic={0.1}
              onDragEnd={handleDragEnd}
            >
              {mobileEmails.map((email, index) => (
                <motion.div key={index} className="w-full flex-shrink-0 px-2">
                  <motion.div
                    className="relative w-full overflow-hidden "
                    initial={{opacity: 0, y: 20}}
                    whileInView={{opacity: 1, y: 0}}
                    viewport={{once: true}}
                    transition={{
                      duration: 0.5,
                      delay: index * 0.1,
                      type: 'spring',
                    }}
                  >
                    <img
                      src={email}
                      alt={`Email example ${index + 1}`}
                      className="w-full h-auto rounded-lg shadow-lg"
                      loading="lazy"
                    />
                  </motion.div>
                </motion.div>
              ))}
            </motion.div>
          </div>

          {/* Mobile Indicators */}
          <div className="flex justify-center gap-3 mt-6">
            {mobileEmails.map((_, index) => (
              <motion.button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`h-2 rounded-full transition-all ${
                  currentSlide === index ? 'bg-orange-500 w-6' : 'border border-orange-500 w-2'
                }`}
                whileHover={{scale: 1.2}}
                whileTap={{scale: 0.95}}
                transition={{type: 'spring', stiffness: 400, damping: 17}}
              />
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Tests;
