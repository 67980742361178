import tw from 'twin.macro';

export const SectionHeading = tw.h2`
  text-3xl sm:text-4xl md:text-5xl 
  font-black 
  tracking-tight 
  text-gray-900 
  mb-6 
  leading-tight
`;

export const SmallSectionHeading = tw(SectionHeading)`
  text-xl sm:text-2xl 
  font-bold 
  tracking-normal
`;

export const DashboardSectionHeading = tw.h2`
  text-2xl md:text-3xl 
  font-semibold 
  tracking-tight 
  text-gray-800 
  mb-4
`;

export const DashboardHeading = tw.h1`
  text-2xl sm:text-3xl md:text-4xl lg:text-5xl 
  text-gray-900 
  tracking-tight 
  leading-tight 
  mb-10 
  text-center 
  sm:text-left 
  max-w-full 
  sm:max-w-screen-lg 
  mx-auto 
  sm:mx-0 
  capitalize 
  flex 
  flex-wrap 
  items-center 
  gap-2
  font-semibold
`;

export const HighlightedDashboardHeading = tw(DashboardHeading)`
  text-primary-600 
  dark:text-primary-400
`;

export const Subheading = tw.h5`
  font-bold 
  text-primary-600
  text-base 
  dark:text-primary-400
`;

export const MediumHeading = tw.h3`
  text-lg 
  font-semibold 
  text-gray-800
`;

export const HighlightedText = tw.span`
  text-primary-600 
  font-semibold 
  dark:text-primary-600
`;

export const BoldText = tw.span`
  font-bold 
  text-gray-900
`;

export const LogoText = tw.span`
  font-semibold 
  tracking-wide 
  text-2xl 
  items-center 
`;

export const ErrorHeading = tw.h2`
  text-2xl 
  font-semibold 
  text-red-500
`;
