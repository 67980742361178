import {images} from 'constants/images';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';

const Features = () => {
  const navigate = useNavigate();

  const features = [
    {
      id: 1,
      icon: images.dyn,
      title: 'Dynamic Email Allocations',
      description:
        'Optimizing throughout the day, every day, to send the most successful test variant',
      status: 'active',
    },
    {
      id: 2,
      icon: images.multi,
      title: 'Multi Variant Testing',
      description: 'Go beyond simple A/B testing with support for up to 7 variants (A/B/C/D/E/F/G)',
      status: 'active',
      highlight: true,
      // tags: ['A/B/C/D/E/F/G'],
    },
    {
      id: 3,
      icon: images.sugg,
      title: 'Email Test Suggestions',
      description:
        'AI-powered suggestions for your next test variants based on historical performance',
      status: 'coming-soon',
    },
  ];

  const containerVariants = {
    hidden: {opacity: 0},
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: {opacity: 0, y: 20},
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
      },
    },
  };

  return (
    <motion.div
      className="w-full bg-[#202122] py-24"
      initial={{opacity: 0}}
      whileInView={{opacity: 1}}
      viewport={{once: true}}
      transition={{duration: 0.8}}
    >
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          className="text-center max-w-3xl mx-auto mb-20"
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: 1, y: 0}}
          viewport={{once: true}}
          transition={{duration: 0.8}}
        >
          <motion.h2
            className="text-4xl md:text-5xl font-semibold font-inter text-white mb-6 leading-tight"
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: 1, y: 0}}
            viewport={{once: true}}
            transition={{duration: 0.8, delay: 0.2}}
          >
            Intelligent Features for
            <br />
            Smarter Email Marketing
          </motion.h2>
          <motion.p
            className="text-white text-lg md:text-xl font-light font-inter leading-relaxed"
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: 1, y: 0}}
            viewport={{once: true}}
            transition={{duration: 0.8, delay: 0.3}}
          >
            Leverage our advanced AI to optimize your email campaigns automatically, saving time
            while maximizing revenue.
          </motion.p>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-8 relative"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{once: true}}
        >
          {features.map(feature => (
            <motion.div key={feature.id} className="relative group" variants={itemVariants}>
              <motion.div
                className="absolute inset-0 bg-gradient-to-b from-orange-500/20 to-transparent opacity-0 group-hover:opacity-100 rounded-3xl"
                transition={{duration: 0.3}}
              />
              <motion.div
                className="relative bg-white/5 backdrop-blur-sm border border-white/10 rounded-3xl p-8 h-full hover:border-orange-500/50 transition-colors"
                whileHover={{
                  y: -5,
                  transition: {type: 'spring', stiffness: 300},
                }}
              >
                {/* Status */}
                <motion.div
                  initial={{opacity: 0, x: -20}}
                  animate={{opacity: 1, x: 0}}
                  transition={{delay: 0.2}}
                >
                  {feature.status === 'coming-soon' ? (
                    <div className="inline-flex items-center text-gray-400 text-sm font-inter mb-8">
                      <span className="w-2 h-2 bg-gray-400 rounded-full mr-2"></span>
                      Coming Soon
                    </div>
                  ) : (
                    <div className="inline-flex items-center text-green-400 text-sm font-inter mb-8">
                      <span className="w-2 h-2 bg-green-400 rounded-full mr-2"></span>
                      Available Now
                    </div>
                  )}
                </motion.div>

                {/* Icon */}
                <motion.div
                  className="mb-6 w-16 h-16"
                  whileHover={{scale: 1.1}}
                  transition={{type: 'spring', stiffness: 400}}
                >
                  <img
                    src={feature.icon}
                    alt={feature.title}
                    className="w-full h-full object-contain"
                  />
                </motion.div>

                {/* Title & Tags */}
                <motion.h3 className="text-2xl font-semibold font-inter text-white mb-4">
                  {feature.title}
                </motion.h3>

                {feature.tags && (
                  <motion.div
                    className="flex gap-2 mb-4"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{delay: 0.4}}
                  >
                    {feature.tags.map(tag => (
                      <motion.span
                        key={tag}
                        className="text-xs font-inter bg-white/10 text-white/80 px-3 py-1 rounded-full"
                        whileHover={{scale: 1.1}}
                        transition={{type: 'spring', stiffness: 400}}
                      >
                        {tag}
                      </motion.span>
                    ))}
                  </motion.div>
                )}

                {/* Description */}
                <motion.p
                  className="text-white/70 font-inter mb-6"
                  initial={{opacity: 0}}
                  animate={{opacity: 1}}
                  transition={{delay: 0.3}}
                >
                  {feature.description}
                </motion.p>
              </motion.div>
            </motion.div>
          ))}
        </motion.div>

        {/* Free Trial Section */}
        <motion.div
          className="mt-16 text-center"
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: 1, y: 0}}
          viewport={{once: true}}
          transition={{duration: 0.8, delay: 0.6}}
        >
          <motion.button
            className="px-8 py-4 bg-orange-500 hover:bg-orange-600 text-white rounded-full font-inter text-lg border border-white/10"
            whileHover={{scale: 1.05}}
            whileTap={{scale: 0.95}}
            transition={{type: 'spring', stiffness: 400, damping: 17}}
            onClick={() => navigate('/signup')}
          >
            Try Us Free
          </motion.button>
          <motion.div
            className="mt-4 space-y-2"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{delay: 0.8}}
          >
            <p className="text-white/60 font-inter">
              No Credit Card Required. No Account Limitations.
            </p>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Features;
