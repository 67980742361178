import React from 'react';
import tw from 'twin.macro';
import {motion, AnimatePresence} from 'framer-motion';
import {Check} from 'lucide-react';

const Overlay = tw.div`
  fixed inset-0 bg-black bg-opacity-50
  flex items-center justify-center 
  z-50
`;

const WizardContainer = tw.div`
  bg-white
  p-6 sm:p-8 md:p-10 
  rounded-lg 
  shadow-2xl 
  w-full 
  max-w-lg 
  mx-auto 
  space-y-6
`;
const StepperContainer = tw.div`flex items-center justify-between mb-8 relative`;
const StepperLine = tw.div`absolute top-1/2 left-0 w-full h-1 bg-gray-200 -translate-y-1/2 rounded-full`;
const StepCircle = tw(
  motion.div
)`w-10 h-10 sm:w-12 sm:h-12 rounded-full flex items-center justify-center text-lg font-medium relative z-10 border-2 border-gray-300 bg-white shadow-lg transition-all duration-300 cursor-pointer`;

const StepLabel = tw.span`text-sm font-semibold`;

export const MultiStepWizard = ({steps, currentStep, onStepComplete}) => {
  return (
    <Overlay>
      <motion.div
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.3}}
      >
        <WizardContainer>
          {/* Stepper */}
          <StepperContainer>
            <StepperLine />
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <StepCircle
                  initial={false}
                  animate={{
                    backgroundColor: currentStep > index ? 'green' : 'white',
                    borderColor: currentStep === index ? '#f97316' : 'white',
                    color: currentStep >= index ? 'white' : '#f97316',
                  }}
                  onClick={() => currentStep > index && onStepComplete(index)}
                >
                  {currentStep > index ? (
                    <Check className="w-4 h-4 sm:w-5 sm:h-5" />
                  ) : (
                    <StepLabel className={currentStep > index ? 'text-white' : 'text-gray-500'}>
                      {index + 1}
                    </StepLabel>
                  )}
                  {currentStep > index && (
                    <Check className="absolute w-4 h-4 sm:w-5 sm:h-5 text-white" />
                  )}
                </StepCircle>
              </React.Fragment>
            ))}
          </StepperContainer>

          {/* Step Content */}
          <AnimatePresence mode="wait">
            <motion.div
              key={currentStep}
              initial={{opacity: 0, x: 20}}
              animate={{opacity: 1, x: 0}}
              exit={{opacity: 0, x: -20}}
              transition={{duration: 0.3}}
            >
              {steps[currentStep].component}
            </motion.div>
          </AnimatePresence>
        </WizardContainer>
      </motion.div>
    </Overlay>
  );
};
