import {collection, doc, getDocs, query, getDoc, updateDoc, where} from 'firebase/firestore';
import {firestore} from 'configuration/firebase/Firebase';
import {Team} from 'helpers/objects/Team';

export const getUsersTeams = async uid => {
  try {
    const q = query(collection(firestore, 'teams'), where('members', 'array-contains-any', [uid]));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length === 0) {
      throw new Error('No team found.');
    }

    // Transform each document snapshot into a Team instance
    const teams = querySnapshot.docs.map(docSnap => {
      const teamData = docSnap.data();
      return new Team(
        docSnap.id, // Assuming the document ID is used as the team ID
        teamData.name,
        teamData.owner,
        teamData.members,
        teamData.trialEnd,
        teamData.isActive,
        teamData.subscriptionStatus,
        teamData.createdAt,
        teamData.stripeCustomerId,
        teamData.subscriptionId
      );
    });

    return teams;
  } catch (error) {
    console.error(error);
  }
};

export const getCurrentTeam = async uid => {
  try {
    if (localStorage.getItem('selectedTeam')) {
      return localStorage.getItem('selectedTeam');
    } else {
      const teams = await getUsersTeams(uid);
      const defaultTeam = teams[0];
      localStorage.setItem('selectedTeam', defaultTeam.id);
      return defaultTeam.id;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getTeamInfo = async uid => {
  try {
    const teamId = await getCurrentTeam(uid);
    const teamRef = doc(firestore, 'teams', teamId);
    const teamSnap = await getDoc(teamRef);
    if (teamSnap.exists()) {
      const teamData = teamSnap.data();
      const team = new Team(
        teamId,
        teamData.name,
        teamData.owner,
        teamData.members,
        teamData.trialEnd,
        teamData.isActive,
        teamData.subscriptionStatus,
        teamData.creeatedAt,
        teamData.stripeCustomerId,
        teamData.subscriptionId
      );
      return team;
    } else {
      throw new Error('No such team.');
    }
  } catch (error) {
    console.error(error);
  }
};
