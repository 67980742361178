import React, {useContext, useEffect, useState} from 'react';
import tw, {styled} from 'twin.macro';
import {useNavigate} from 'react-router-dom';
import {getFunctions, httpsCallable} from 'firebase/functions';

// Component imports
import Header from 'components/headers/light';
import ExperimentTable from 'components/experiment/ExperimentTable';
import {ContentWithPaddingXl, PageContainer} from 'components/items/Layouts';
import {DashboardHeading, HighlightedText, MediumHeading} from 'components/items/Headings';
import {OptionsButton} from 'components/items/Buttons';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import SimpleCustomSelectMenu from '../components/forms/SimpleCustomSelectMenu';
import InfoTooltip from '../helpers/tour/InfoTooltip';
import {MultiStepWizard} from 'components/common/MultiStepWizard/MultiStepWizard';
import {KlaviyoConnectStep} from 'components/setup/steps/KlaviyoConnectStep';
import BrandSetupStep from 'components/setup/steps/BrandSetupStep';

// Helper imports
import {IsLoggedIn} from 'helpers/api/auth/auth';
import {getTeamInfo, getUsersTeams} from '../helpers/api/firestore/Teams';
import {getActiveExperimentsByTeam} from '../helpers/api/firestore/Experiments';
import {TourContext} from '../helpers/tour/TourContext';
import {SubscriptionStatusBanner} from 'components/items/SubscriptionStatusBanner';
import {checkSubscriptionStatus} from 'helpers/api/functions/Stripe';
import {checkAccountConnection} from 'helpers/api/functions/Oauth';

const CreateButtonContainer = tw.button`w-full flex justify-center text-primary-500`;
const StartTourButton = tw.button`underline hocus:text-primary-500`;
const InlineContainer = tw.div`inline-flex`;

const Dashboard = () => {
  const navigate = useNavigate();
  const [user] = IsLoggedIn();

  // State management
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [experiments, setExperiments] = useState([]);
  const [newTeamName, setNewTeamName] = useState('');
  const [teamCreationError, setTeamCreationError] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [currentSetupStep, setCurrentSetupStep] = useState(0);

  // Tour-related state
  const {run, stepIndex, setRun, setStepIndex} = useContext(TourContext);
  const [continueTour, setContinueTour] = useState(false);

  const functions = getFunctions();

  // Effect to load subscription status and initial team data
  useEffect(() => {
    const loadInitialData = async () => {
      if (!selectedTeam) {
        const team = await getTeamInfo(user.uid);
        if (team) {
          setSelectedTeam(team);
          const userTeams = await getUsersTeams(user.uid);
          setTeams(userTeams);
        }
      }

      if (selectedTeam) {
        setCurrentSetupStep(1);
        const isAccountConnected = await checkAccountConnection(selectedTeam.id);
        setIsConnected(isAccountConnected);
        const activeExperiments = await getActiveExperimentsByTeam(selectedTeam.id);
        setExperiments(activeExperiments);

        try {
          const status = await checkSubscriptionStatus(selectedTeam.id);
          setSubscriptionStatus(status);
        } catch (error) {
          console.error('Failed to fetch subscription status', error);
        }
      }
      setLoading(false);
      // Check subscription status
    };

    loadInitialData();
  }, [selectedTeam, user]);

  // Effect to handle tour state
  useEffect(() => {
    if (run && !selectedTeam && !isConnected) {
      setRun(false);
      setContinueTour(true);
    }
    if (continueTour && selectedTeam && isConnected) {
      setContinueTour(false);
      setRun(true);
    }
  }, [loading, selectedTeam, isConnected]);

  // Handlers
  const handleTeamChange = e => {
    const team = teams.find(t => t.name === e.target.value);
    setSelectedTeam(team);
    localStorage.setItem('selectedTeam', team.id);
  };

  const handleCreateTeam = async () => {
    setLoading(true);

    if (!newTeamName.trim()) {
      setTeamCreationError('Team name cannot be empty');
      return;
    }

    try {
      const createTeamFunc = httpsCallable(functions, 'create_team');
      const res = await createTeamFunc({teamName: newTeamName.trim()});

      if (res.data.teamId) {
        localStorage.setItem('selectedTeam', res.data.teamId);

        const team = await getTeamInfo(user.uid);
        if (team) {
          setSelectedTeam(team);
          const userTeams = await getUsersTeams(user.uid);
          setTeams(userTeams);
          // Move to next step after successful team creation
          setCurrentSetupStep(1);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating team:', error);
      setTeamCreationError(error.message || 'Failed to create team');
      setLoading(false);
    }
  };

  // Modify create experiment button to check subscription
  const handleCreateExperiment = () => {
    if (!subscriptionStatus?.isActive) {
      navigate('/my-account');
      return;
    }
    navigate('/create-experiment');
  };

  const steps = [
    {
      title: 'Brand Setup',
      component: (
        <BrandSetupStep
          newTeamName={newTeamName}
          setNewTeamName={setNewTeamName}
          teamCreationError={teamCreationError}
          handleCreateTeam={handleCreateTeam}
        />
      ),
    },
    {
      title: 'Connect Klaviyo',
      component: <KlaviyoConnectStep teamId={selectedTeam?.id} />,
    },
  ];

  // Render loading state
  if (loading) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      </PageContainer>
    );
  }

  if (!isConnected || !selectedTeam) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <MultiStepWizard
          steps={steps}
          currentStep={currentSetupStep}
          onStepComplete={() => {
            // This will be called when Klaviyo connection is complete
            // You can add any additional logic here
          }}
        />
      </PageContainer>
    );
  }

  // Main dashboard view
  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      <SubscriptionStatusBanner subscriptionStatus={subscriptionStatus} />

      <ContentWithPaddingXl id={'dashboard-body'}>
        {' '}
        <div className="w-full justify-items-center">
          <DashboardHeading>Experiments</DashboardHeading>
        </div>
        {/* Team selection section */}
        {teams.length > 1 ? (
          <SimpleCustomSelectMenu
            htmlId={'team'}
            label={'Select Brand'}
            value={selectedTeam.name}
            options={teams.map(team => team.name)}
            onChange={handleTeamChange}
          />
        ) : (
          <InlineContainer>
            Manage multiple Klaviyo accounts?
            <InfoTooltip
              id={'multiple-teams'}
              content={
                'Please get in touch with us on hello@mailbandit.ai and we can provide you with a bespoke setup.'
              }
            />
          </InlineContainer>
        )}
        <div className="my-10">
          {/* Experiments section */}
          <MediumHeading>
            You have&nbsp;
            <HighlightedText>{experiments.length}</HighlightedText> &nbsp;experiment
            {experiments.length > 1 ? 's' : ''} running
          </MediumHeading>
          <ExperimentTable listOfExperiments={experiments} />

          {/* Action buttons */}
          <CreateButtonContainer>
            <OptionsButton onClick={handleCreateExperiment} id="create-experiment">
              {subscriptionStatus?.isActive
                ? 'Create New Experiment'
                : 'Upgrade To Create Experiments'}
            </OptionsButton>
          </CreateButtonContainer>
        </div>
        <StartTourButton
          id={'tour-button'}
          onClick={() => {
            setStepIndex(0);
            setRun(true);
          }}
        >
          Show me around
        </StartTourButton>
      </ContentWithPaddingXl>
    </PageContainer>
  );
};

export default Dashboard;
