import React from 'react';
import tw from 'twin.macro';
import {SectionDescription} from './Typography';
import {OptionsButton} from './Buttons';
import {FaRegCopy} from 'react-icons/fa';
import {Dialog} from '@headlessui/react';
// import {X} from 'lucide-react';
import {motion} from 'framer-motion';

const ModalHeader = tw.div`
  border-b 
  border-gray-200 
`;

const ModalBody = tw.div`
  p-6 
  overflow-y-auto 
  max-h-[50vh]
  text-sm 
  text-gray-600`;

const ModalOverlay = tw.div`fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50`;
const ModalContainer = tw(motion.div)`bg-white rounded-lg shadow-lg p-6 max-w-md w-full`;
const ModalTitle = tw.h2`text-lg font-semibold text-gray-800 text-center`;
// const CloseButton = tw.button`text-gray-500 hover:text-gray-700`;
const ModalFooter = tw.div`mt-6 flex justify-end w-full`;

export const Modal = ({
  isOpen,
  modalHeading = 'Some Heading',
  modalBody = 'Please copy and paste the following into your Klaviyo webhook for the experiment to work:',
  modalButtons = [
    {
      text: 'Close',
      action: () => {
        console.log('close clicked');
      },
      buttonTag: OptionsButton,
    },
  ],
}) => {
  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <ModalOverlay>
        <ModalContainer
          initial={{opacity: 0, y: -20}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -20}}
          transition={{duration: 0.3}}
        >
          <ModalHeader>
            <ModalTitle>{modalHeading}</ModalTitle>
            {/* <CloseButton onClick={onClose}>
                <X className="w-5 h-5" />
              </CloseButton> */}
          </ModalHeader>
          <ModalBody>{modalBody}</ModalBody>
          <ModalFooter>
            {modalButtons.map((button, index) => (
              <button.buttonTag key={index} onClick={button.action} className="mr-2">
                {button.text}
              </button.buttonTag>
            ))}
          </ModalFooter>
        </ModalContainer>
      </ModalOverlay>
    </Dialog>
  );
};

export const ModalCopyLineItem = ({text, iconStates, index, setIconStates}) => {
  const ModalLineItem = tw.div`
    flex items-center justify-between 
    py-2 
    border-b 
    border-gray-200 
    last:border-b-0
  `;

  const CopyButton = tw(FaRegCopy)`
    text-gray-500 
    hover:text-blue-600 
    cursor-pointer 
    transition-colors
  `;

  const handleClick = index => {
    setIconStates(prevStates => {
      const newState = [...prevStates];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <ModalLineItem>
      <SectionDescription className="mr-4 flex-grow ">{text}</SectionDescription>
      {iconStates[index] ? (
        <SectionDescription className="text-green-600 font-semibold">Copied!</SectionDescription>
      ) : (
        <CopyButton
          onClick={() => {
            navigator.clipboard.writeText(text).then(() => {
              handleClick(index);
              setTimeout(() => {
                handleClick(index);
              }, 1000);
            });
          }}
        />
      )}
    </ModalLineItem>
  );
};

export default Modal;
