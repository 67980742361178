import {images} from 'constants/images';
import {useNavigate} from 'react-router-dom';

const Klaviyo = () => {
  const navigate = useNavigate();
  return (
    <div className="relative w-full min-h-[600px] flex items-center">
      {/* Background Image with Gradient */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${images.klav})`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/60 to-transparent"></div>
      </div>

      {/* Content */}
      <div className="relative w-full max-w-7xl mx-auto px-6 lg:px-8 py-24">
        <div className="max-w-2xl">
          {/* Main Content */}
          <h2 className="text-4xl md:text-5xl font-semibold font-inter text-white mb-6 leading-tight">
            Seamless Integration with <span className="text-[#EE505B]">Klaviyo</span>
          </h2>

          <p className="text-white/80 text-lg md:text-xl font-light font-inter leading-relaxed mb-8 max-w-xl">
            Connect your Klaviyo account instantly and get MailBandit running. Our intelligent A/B
            testing solution integrates directly with your existing email infrastructure, providing
            immediate optimisation with zero workflow disruption.
          </p>

          {/* Platform Support Info */}
          <div className="space-y-4 mb-12">
            <div className="flex items-center space-x-3">
              <div className="w-5 h-5 rounded-full bg-green-500 flex items-center justify-center">
                <span className="text-white text-sm">✓</span>
              </div>
              <span className="text-white/90 font-inter">Instant Klaviyo flow optimisation</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="w-5 h-5 rounded-full bg-green-500 flex items-center justify-center">
                <span className="text-white text-sm">✓</span>
              </div>
              <span className="text-white/90 font-inter">Zero configuration required</span>
            </div>
            <div className="flex items-center space-x-3">
              <div className="w-5 h-5 rounded-full bg-zinc-500 flex items-center justify-center">
                <span className="text-white text-sm">→</span>
              </div>
              <span className="text-white/70 font-inter">Mailchimp integration coming soon</span>
            </div>
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row gap-4">
            <button
              className="px-8 py-4 bg-[#EE505B] hover:bg-[#DC4251] text-white rounded-full font-inter transition-all hover:scale-105"
              onClick={() => navigate('/signup')}
            >
              Get Started Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Klaviyo;
