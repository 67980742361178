import { motion } from 'framer-motion';
import { images } from 'constants/images';
import { HighlightedText } from 'components/items/Headings';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="relative min-h-screen flex items-center">
      {/* Background Image with Gradient */}
      <motion.div
        className="absolute inset-0 bg-cover bg-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        style={{
          backgroundImage: `url(${images.email_marketing_image})`,
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/60 to-black/70"></div>
      </motion.div>

      {/* Content */}
      <div className="relative w-full max-w-7xl mx-auto px-6 lg:px-8">
        <div className="flex flex-col items-center md:items-start">
          {/* Badge */}
          <motion.div
            className="mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="inline-flex items-center bg-white/10 backdrop-blur-md rounded-full px-5 py-2.5 border border-white/10">
              <div className="w-2 h-2 bg-green-400 rounded-full mr-3"></div>
              <span className="text-white/90 text-base font-inter">Now Available for Klaviyo</span>
            </div>
          </motion.div>

          {/* Main Content */}
          <motion.h1
            className="text-6xl md:text-6xl lg:text-7xl font-semibold leading-[1.1] font-inter tracking-tight mb-6 text-white text-center md:text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            A/B Testing <br /> <HighlightedText> With AI Precision</HighlightedText>
          </motion.h1>

          <motion.p
            className="text-white text-lg md:text-xl max-w-2xl font-light font-inter leading-relaxed mb-10 text-center md:text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            Deliver the right message at the right moment with MailBandit's real-time A/B testing and AI-driven traffic allocation. Maximise engagement and conversions without the guesswork.
          </motion.p>

          {/* CTA Section */}
          <motion.div
            className="flex flex-col items-center md:items-start gap-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
          >
            <motion.button
              className="w-full md:w-auto px-8 py-4 bg-orange-500 hover:bg-orange-600 text-white rounded-full font-inter text-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              onClick={() => navigate('/signup')}
            >
              Start Your 30 Day Free Trial
            </motion.button>
            
            <motion.p
              className="w-full md:w-auto px-8 bg-transparent text-white/80 text-sm md:text-base text-center font-light font-inter mt-2"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 1 }}
            >
              No credit card details required
            </motion.p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;