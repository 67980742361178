import {useRef} from 'react';
import Navbar from 'components/landing_page/Navbar'; // Capitalized
import Hero from 'components/landing_page/Hero';
import LogoCarousel from 'components/items/LogoCarousel';
import Product from 'components/landing_page/Product';
import Tests from 'components/landing_page/Tests';
import Klaviyo from 'components/landing_page/Klaviyo';
import Features from 'components/landing_page/Features';
import Closing from 'components/landing_page/Closing';
import Footer from 'components/headers/Footer';

function LandingPage() {
  const featuresRef = useRef(null);
  const integrationRef = useRef(null);
  const pricingRef = useRef(null);
  const footerRef = useRef(null);
  const productRef = useRef(null);
  const casestudyRef = useRef(null);
  const homeRef = useRef(null);

  const refs = {
    home: homeRef,
    product: productRef,
    features: featuresRef,
    integration: integrationRef,
    pricing: pricingRef,
    footer: footerRef,
    casestudy: casestudyRef,
  };

  return (
    <div className="min-h-screen bg-black">
      <Navbar refs={refs} />
      <div ref={homeRef}>
        <Hero />
      </div>
      <div ref={productRef}>
        <Product />
      </div>
      <LogoCarousel />
      <div ref={casestudyRef}>
        <Tests />
      </div>
      <div ref={integrationRef}>
        <Klaviyo />
      </div>
      <div ref={featuresRef}>
        <Features />
      </div>
      <Closing />
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;
