import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Navbar from 'components/landing_page/Navbar';
import LogoCarousel from '../components/items/LogoCarousel';
import Closing from '../components/landing_page/Closing';
import Footer from '../components/headers/Footer';
import { Link } from 'react-router-dom';

const PricingCard = ({ plan, prices, features, isPopular, currency }) => {
  const price = currency === 'USD' ? prices.usd : prices.gbp;
  const currencySymbol = currency === 'USD' ? '$' : '£';

  return (
    <motion.div
      className="relative rounded-3xl backdrop-blur-lg overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <div className={`absolute inset-0 ${
        isPopular
          ? 'bg-gradient-to-b from-orange-500/20 via-black to-black border border-orange-500/30'
          : 'bg-gradient-to-b from-white/10 via-black to-black border border-white/10'
      }`} />

      {isPopular && (
        <>
          <div className="absolute -top-20 -right-20 w-40 h-40 bg-orange-500/30 blur-3xl rounded-full" />
          <div className="absolute -bottom-20 -left-20 w-40 h-40 bg-orange-500/20 blur-3xl rounded-full" />
        </>
      )}

      <div className="relative z-10 p-8">
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-white mb-6 font-inter">{plan}</h3>
          <div className="mb-2">
            <div className="flex items-baseline gap-1">
              <span className="text-5xl font-bold text-white font-inter tracking-tight">
                {typeof price === 'string' ? price : `${currencySymbol}${price.toFixed(2)}`}
              </span>
              {typeof price === 'number' && (
                <span className="text-lg text-white/60 font-inter">/month</span>
              )}
            </div>
          </div>
        </div>

        <ul className="space-y-4 mb-8 min-h-[280px]">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center text-white/80 font-inter">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: index * 0.1 }}
                className={`mr-3 flex-shrink-0 h-5 w-5 rounded-full flex items-center justify-center ${
                  isPopular ? 'bg-orange-500/20' : 'bg-white/10'
                }`}
              >
                <svg
                  className={`w-3 h-3 ${isPopular ? 'text-orange-400' : 'text-white/60'}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </motion.div>
              <span className="text-[15px]">{feature}</span>
            </li>
          ))}
        </ul>

        <Link
          to="/signup"
          className={`
            w-full py-4 rounded-xl font-medium font-inter text-white inline-block text-center
            transition-colors duration-200
            ${isPopular ? 'bg-orange-500 hover:bg-orange-600' : 'bg-white/10 hover:bg-white/20'}
          `}
        >
          {isPopular ? '⚡ Start Today' : 'Start Today'}
        </Link>
      </div>
    </motion.div>
  );
};

const PricingPage = () => {
  const [currency, setCurrency] = useState('USD');

  const plans = [
    {
      plan: 'Freelancer',
      prices: { usd: 129.99, gbp: 99.99 },
      features: [
        'Unlimited Email Variations',
        'Test Up To 15 Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Up To 5 Members Of Your Team',
        <div className="flex items-center text-orange-400 font-medium">
          <div className="w-1.5 h-1.5 bg-orange-400 rounded-full animate-pulse" />
          Try free for 30 days, cancel anytime
        </div>
      ],
    },
    {
      plan: 'Startup',
      prices: { usd: 199.99, gbp: 149.99 },
      features: [
        'Unlimited Email Variations',
        'Test Up To 50 Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Up To 10 Members Of Your Team',
        <div className="flex items-center text-orange-400 font-medium">
          <div className="w-1.5 h-1.5 bg-orange-400 rounded-full animate-pulse" />
          Try free for 30 days, cancel anytime
        </div>
      ],
    },
    {
      plan: 'Enterprise',
      prices: { usd: 'Contact Us', gbp: 'Contact Us' },
      features: [
        'Unlimited Email Variations',
        'Unlimited Workflows',
        'Chat + Email + Phone Support',
        'Unlimited Users',
        'Unlimited Members Of Your Team',
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      
      {/* Hero Section */}
      <div id="pricing-header" className="relative pt-20 pb-32 overflow-hidden">
        {/* Background Elements */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-orange-500/10 via-black to-black" />
          <div className="absolute top-0 left-1/4 w-96 h-96 bg-orange-500/20 rounded-full blur-3xl" />
          <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-orange-500/10 rounded-full blur-3xl" />
        </div>

        <div className="relative z-10 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
          <motion.div 
              className="inline-flex items-center bg-white/10 backdrop-blur-md rounded-full px-5 py-2.5 border border-white/10 mb-8 hover:bg-white/20 transition-colors"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
              <div className="w-2 h-2 bg-green-700 rounded-full mr-3 animate-pulse"></div>
              <span className="text-white/90 text-sm md:text-base font-inter">1 Month Free Trial</span>
            </motion.div>

            <h1 className="text-4xl md:text-5xl lg:text-6xl font-semibold text-white mb-6 font-inter leading-tight">
              Power Up Your
              <br className="hidden md:block" />
              <span className="text-orange-400">Email Marketing</span>
            </h1>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.8 }}
            >
              <p className="text-lg md:text-xl text-white/80 max-w-2xl mx-auto font-inter leading-relaxed mb-12">
                Choose the perfect plan for your business and unlock the full potential of AI-driven
                email optimization. All plans include advanced analytics and dedicated support.
              </p>
            </motion.div>

            <div className="mt-12 flex justify-center">
              <div className="inline-flex p-1 bg-white/5 backdrop-blur-sm rounded-full border border-white/10">
                <button
                  onClick={() => setCurrency('USD')}
                  className={`px-6 py-2 rounded-full font-inter transition-colors duration-200 ${
                    currency === 'USD'
                      ? 'bg-orange-500 text-white'
                      : 'text-white/60 hover:text-white'
                  }`}
                >
                  USD
                </button>
                <button
                  onClick={() => setCurrency('GBP')}
                  className={`px-6 py-2 rounded-full font-inter transition-colors duration-200 ${
                    currency === 'GBP'
                      ? 'bg-orange-500 text-white'
                      : 'text-white/60 hover:text-white'
                  }`}
                >
                  GBP
                </button>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-7xl mx-auto">
            {plans.map((plan, index) => (
              <div key={plan.plan} className="relative pt-6 md:pt-4">
                <PricingCard
                  plan={plan.plan}
                  prices={plan.prices}
                  features={plan.features}
                  isPopular={index === 1}
                  currency={currency}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <LogoCarousel />
      <Closing />
      <Footer />
    </div>
  );
};

export default PricingPage;