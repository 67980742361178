import {useState, useEffect} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {Link, useLocation} from 'react-router-dom';
import mbLogo from 'assets/images/new_landing/mblogo.webp';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Rest of your component code remains the same
  const navItems = [
    // { name: 'About', path: '/about' },
    {name: 'Pricing', path: '/pricing'},
    {name: 'Contact', path: '/contact'},
  ];

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 20);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <motion.nav
        initial={{y: -100}}
        animate={{y: 0}}
        transition={{type: 'spring', stiffness: 300, damping: 30}}
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled || isMobileMenuOpen ? 'bg-black/80 backdrop-blur-lg' : 'bg-transparent'
        }`}
      >
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="flex items-center justify-between h-20">
            {/* Logo Section */}
            <Link to="/" className="flex items-center space-x-3">
              <img src={mbLogo} alt="Logo" className="h-8 w-auto" />
              <span className="text-2xl font-semibold font-inter">
                <span className="text-white">Mail</span>
                <span className="text-orange-500">Bandit</span>
              </span>
            </Link>

            {/* Centered Navigation Links */}
            <div className="hidden lg:flex items-center justify-center flex-1">
              <div className="flex items-center space-x-6">
                {navItems.map(item => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className="text-sm text-white/80 hover:text-white transition-colors font-inter"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Right Section: Login/Signup */}
            <div className="flex items-center space-x-4">
              <Link
                to="/login"
                className="hidden lg:block text-white px-6 py-2.5 text-sm font-inter rounded-full border border-orange-500 hover:bg-white hover:text-orange-500 hover:border-transparent"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="bg-orange-500 hover:bg-orange-600 text-white px-6 py-2.5 rounded-full text-sm font-inter"
              >
                Sign up
              </Link>
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="lg:hidden text-white"
              >
                {isMobileMenuOpen ? (
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </motion.nav>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            <motion.div
              initial={{opacity: 0}}
              animate={{opacity: 1}}
              exit={{opacity: 0}}
              className="fixed inset-0 bg-black/50 z-40 lg:hidden"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            <motion.div
              initial={{x: '100%'}}
              animate={{x: 0}}
              exit={{x: '100%'}}
              transition={{type: 'spring', damping: 30}}
              className="fixed top-0 right-0 bottom-0 w-3/4 bg-black z-50 lg:hidden"
            >
              <div className="flex flex-col h-full">
                <div className="flex justify-end p-6">
                  <button onClick={() => setIsMobileMenuOpen(false)} className="text-white">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <div className="flex-1 px-6">
                  {navItems.map((item, index) => (
                    <div key={item.name} className="border-b border-white/10">
                      <Link
                        to={item.path}
                        className="block py-4 text-white/80 fonthover:text-white font-inter"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </div>

                <div className="p-6 space-y-3 mt-auto">
                  <Link
                    to="/signup"
                    className="block w-full text-center bg-orange-500 hover:bg-orange-600 text-white py-3 rounded-full font-inter"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Sign up
                  </Link>
                  <Link
                    to="/contact"
                    className="block w-full text-center border border-white/50 text-white py-3 rounded-full font-inter hover:bg-white/5"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Get a demo
                  </Link>
                  <Link
                    to="/login"
                    className="block w-full text-center text-white/80 hover:text-white py-2 font-inter"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    Log in
                  </Link>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
