import React, {useEffect, useState, useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LoadingContainer, PageLoading} from 'components/items/Loading'; // Adjust the import path as necessary
import {handleOAuthCallback} from 'helpers/api/functions/Oauth'; // Import the new function
import tw from 'twin.macro';
import {PageContainer} from 'components/items/Layouts';
import Header from 'components/headers/light';
import {CheckCircle, XCircle} from 'lucide-react'; // Importing tick and error icons
import {motion} from 'framer-motion';
import Confetti from 'react-confetti';
import {TourContext} from '../helpers/tour/TourContext';

const SuccessMessage = tw(
  motion.div
)`flex flex-col items-center bg-white p-6 rounded-lg shadow-lg mt-10 w-full max-w-md mx-auto`;
const ErrorMessage = tw(
  motion.div
)`flex flex-col items-center bg-red-100 p-6 rounded-lg shadow-lg mt-10 w-full max-w-md mx-auto`;
const MessageText = tw.p`text-lg font-semibold text-gray-800`;
const InstructionsText = tw.p`text-sm text-gray-600 mt-2`;
const HomeButton = tw.button`mt-4 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors`;

const KlaviyoCallbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use history for navigation
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const {setRun} = useContext(TourContext);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');

    const processOAuthCallback = async () => {
      try {
        const result = await handleOAuthCallback(code, state); // Use the imported function

        if (result.success) {
          setSuccess(true);
        } else {
          setError('Failed to connect your account. Please try again.');
        }
      } catch (err) {
        console.error('Error handling OAuth callback:', err);
        setError('An error occurred while processing your request.');
      } finally {
        setLoading(false);
      }
    };

    if (code && state) {
      processOAuthCallback();
    } else {
      setError('Invalid request. Missing code or state.');
      setLoading(false);
    }
  }, [location]);

  if (loading) {
    return (
      <PageContainer>
        <Header isLoggedIn={true} />
        <LoadingContainer>
          <PageLoading />
        </LoadingContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header isLoggedIn={true} />
      {success && <Confetti />}
      <div>
        {success ? (
          <SuccessMessage
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
          >
            <motion.div
              initial={{scale: 0}}
              animate={{scale: 1}}
              transition={{type: 'spring', stiffness: 300}}
            >
              <CheckCircle className="w-12 h-12 text-green-500" />
            </motion.div>
            <MessageText>Congratulations!</MessageText>
            <InstructionsText>Your account has been successfully connected!</InstructionsText>
            <InstructionsText>You can now begin setting up experiments.</InstructionsText>
            <HomeButton
              onClick={() => {
                setRun(true);
                navigate('/dashboard');
              }}
            >
              Go to Home
            </HomeButton>
          </SuccessMessage>
        ) : (
          <ErrorMessage
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
          >
            <motion.div
              initial={{scale: 0}}
              animate={{scale: 1}}
              transition={{type: 'spring', stiffness: 300}}
            >
              <XCircle className="w-12 h-12 text-red-500" />
            </motion.div>
            <MessageText className="text-red-600">Error!</MessageText>
            <InstructionsText>{error}</InstructionsText>
            <HomeButton onClick={() => navigate('/dashboard')}>Try again</HomeButton>
          </ErrorMessage>
        )}
      </div>
    </PageContainer>
  );
};

export default KlaviyoCallbackPage;
