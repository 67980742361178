import dress1 from 'assets/images/RWH-Welcome-2.png';
import dress2 from 'assets/images/RWH-Welcome-3.png';
import pythia from 'assets/images/new_landing/pythia.png';
import rosewater from 'assets/images/new_landing/rosewater.png';
import mbmain from 'assets/images/new_landing/mbmain.png';
import klav from 'assets/images/new_landing/pexels-ann-h-45017-3482441.jpg';
import sugg from 'assets/images/new_landing/sugg.png';
import dyn from 'assets/images/new_landing/dyn.png';
import multi from 'assets/images/new_landing/multi.png';
import email_marketing_image from 'assets/images/too-much-email-stock-photo.jpg';
import team from 'assets/images/team.png';
import loginSide from '../assets/images/login-side.jpg';
import loginSide2 from '../assets/images/login-side-2.jpg';

export const images = {
  dress1,
  dress2,
  pythia,
  rosewater,
  mbmain,
  klav,
  sugg,
  dyn,
  multi,
  email_marketing_image,
  team,
  loginSide,
  loginSide2,
};
