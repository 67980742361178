import React, {useEffect, useState} from 'react';
import tw from 'twin.macro';
import klaviyo_logo from 'assets/images/klaviyo-primary-logo-charcoal-medium.png';
import {getKlaviyoOAuthUrl} from 'helpers/api/functions/Oauth';

const ResponsiveHeading = tw.h2`text-2xl sm:text-3xl font-semibold mb-4 text-center`;
const ResponsiveParagraph = tw.p`text-base sm:text-lg mb-6 text-center`;
const ConnectButton = tw.a`px-6 py-3 bg-orange-500 text-white rounded-lg font-medium hover:bg-orange-600 transition-colors inline-flex items-center justify-center`;

export const KlaviyoConnectStep = ({teamId}) => {
  const [oauthUrl, setOauthUrl] = useState('');

  useEffect(() => {
    const fetchOAuthUrl = async () => {
      try {
        const url = await getKlaviyoOAuthUrl(teamId);
        setOauthUrl(url);
      } catch (error) {
        console.error('Error fetching OAuth URL:', error);
      }
    };

    fetchOAuthUrl();
  }, [teamId]);

  return (
    <div className="space-y-6">
      <div className="flex justify-center">
        <img
          src={klaviyo_logo}
          alt="Klaviyo Logo"
          className="w-1/2" // Responsive width
        />
      </div>
      <ResponsiveHeading>Connect Your Klaviyo Account</ResponsiveHeading>
      <ResponsiveParagraph>
        Link your Klaviyo account to start optimizing your flows.
      </ResponsiveParagraph>
      <div className="flex justify-center">
        <ConnectButton
          href={oauthUrl}
          target="_blank"
          rel="noopener noreferrer"
          disabled={!oauthUrl} // Disable if oauthUrl is not ready
        >
          Connect with Klaviyo
        </ConnectButton>
      </div>
    </div>
  );
};
