// src/components/LogoCarousel.jsx
import {useEffect, useState} from 'react';
import {images} from 'constants/images';

const LogoCarousel = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const carouselImages = [
    {
      src: images.pythia,
      alt: 'Pythia',
    },
    {
      src: images.rosewater,
      alt: 'Rosewater',
    },
    {
      src: images.pythia,
      alt: 'Pythia',
    },
    {
      src: images.rosewater,
      alt: 'Rosewater',
    },
  ];

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      setScrollPosition(prev => (prev - 1) % (carouselImages.length * 250));
    }, 30);

    return () => clearInterval(scrollInterval);
  }, []);

  const duplicatedImages = [...carouselImages, ...carouselImages];

  return (
    <div className="w-full bg-black py-16">
      <div className="max-w-5xl mx-auto px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <p className="text-white text-xl font-light font-inter">
            Trusted by leading eCommerce brands:
          </p>
        </div>

        <div className="overflow-hidden">
          <div
            className="flex items-center space-x-16 whitespace-nowrap"
            style={{
              transform: `translateX(${scrollPosition}px)`,
              transition: 'transform 0.03s linear',
            }}
          >
            {duplicatedImages.map((image, index) => (
              <div key={index} className="flex-shrink-0 h-12">
                <img
                  src={image.src}
                  alt={image.alt}
                  className="h-full w-auto object-contain brightness-0 invert opacity-50 hover:opacity-75 transition-opacity"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;
