import React, {useState, useContext} from 'react';
import {motion} from 'framer-motion';
import {Mail, Lock, AlertCircle} from 'lucide-react';
import {Link, useNavigate} from 'react-router-dom';
import {images} from '../constants/images';
import mbLogo from '../assets/images/new_landing/mblogo.webp';
import tw from 'twin.macro';
import {SignInWithGoogle, signUpWithEmailPwd} from 'helpers/api/auth/auth';
import {LoadingContainer, PageLoading} from 'components/items/Loading';
import {getAdditionalUserInfo} from 'firebase/auth';
import {TourContext} from '../helpers/tour/TourContext';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

const Input = tw.input`block w-full pl-12 pr-4 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder-gray-400 focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-colors font-inter`;

const ErrorMessage = ({message}) => (
  <div className="flex items-start mt-1.5">
    <AlertCircle className="w-4 h-4 text-red-500 mr-1.5 flex-shrink-0 mt-0.5" />
    <span className="text-red-500 text-sm font-inter">{message}</span>
  </div>
);

// Add validation schema
const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*]/, 'Password must contain at least one special character (!@#$%^&*)')
    .required('Password is required'),
});

const SignUp = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const navigate = useNavigate();
  const {setRun} = useContext(TourContext);
  const [generalError, setGeneralError] = useState('');

  const handleSubmit = async (values, {setSubmitting}) => {
    setGeneralError('');
    setPageLoading(true);

    try {
      const response = await signUpWithEmailPwd(values.email, values.password);
      const result = {
        userCredential: response,
        additionalUserInfo: getAdditionalUserInfo(response),
      };
      navigate('/dashboard');
      setRun(true);
    } catch (error) {
      let errorMessage = 'Unable to sign up. Please try again later.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already registered. Please sign in instead.';
      }
      setGeneralError(errorMessage);
    }

    setPageLoading(false);
    setSubmitting(false);
  };

  const handleSignInWithGoogle = () => {
    setPageLoading(true);
    setGeneralError('');

    SignInWithGoogle()
      .then(r => {
        return {userCredential: r};
      })
      .then(r => {
        navigate('/dashboard');
        setPageLoading(false);
        setRun(true);
      })
      .catch(e => {
        setPageLoading(false);
        setGeneralError('Unable to sign up with Google. Please try again later.');
      });
  };

  if (pageLoading) {
    return (
      <LoadingContainer>
        <PageLoading />
      </LoadingContainer>
    );
  }

  return (
    <div className="h-screen w-full flex flex-col lg:flex-row">
      {/* Form Section */}
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 0.6}}
        className="flex-1 h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8 bg-black"
      >
        <div className="w-full max-w-sm space-y-6">
          {/* Logo */}
          <motion.div
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: 0.3}}
            className="flex justify-center"
          >
            <Link to="/" className="flex items-center space-x-3">
              <img src={mbLogo} alt="MailBandit Logo" className="h-8 w-auto" />
              <span className="text-2xl font-semibold font-inter">
                <span className="text-white">Mail</span>
                <span className="text-orange-500">Bandit</span>
              </span>
            </Link>
          </motion.div>

          {/* Title */}
          <motion.h2
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: 0.4}}
            className="text-3xl font-semibold text-white text-center font-inter"
          >
            Sign Up
          </motion.h2>

          <div className="space-y-4">
            {/* General Error Message */}
            {generalError && (
              <motion.div
                initial={{opacity: 0, y: -10}}
                animate={{opacity: 1, y: 0}}
                className="bg-red-500/10 border border-red-500/20 rounded-lg p-3"
              >
                <ErrorMessage message={generalError} />
              </motion.div>
            )}

            {/* Google Sign Up Button */}
            <motion.button
              type="button"
              onClick={handleSignInWithGoogle}
              whileHover={{scale: 1.02}}
              whileTap={{scale: 0.98}}
              className="w-full relative bg-white rounded-xl hover:bg-gray-50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <div className="w-full flex items-center justify-center px-4 py-3">
                <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
                  <path
                    fill="#4285F4"
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                  />
                  <path
                    fill="#34A853"
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                  />
                  <path
                    fill="#EA4335"
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                  />
                </svg>
                <span className="text-gray-700 font-medium font-inter">Sign Up With Google</span>
              </div>
            </motion.button>

            <div className="relative py-2">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-700"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-4 bg-black text-gray-400 font-inter">
                  or sign up with email
                </span>
              </div>
            </div>

            {/* Replace the form section with Formik */}
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={SignupSchema}
              onSubmit={handleSubmit}
            >
              {({errors, touched, isSubmitting}) => (
                <Form className="space-y-4">
                  {/* Email Input */}
                  <div className="space-y-1">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                        <Mail className="h-5 w-5 text-gray-400" />
                      </div>
                      <Field
                        as={Input}
                        type="email"
                        name="email"
                        placeholder="Email"
                        className={errors.email && touched.email ? 'border-red-500' : ''}
                      />
                    </div>
                    {errors.email && touched.email && <ErrorMessage message={errors.email} />}
                  </div>

                  {/* Password Input */}
                  <div className="space-y-1">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                        <Lock className="h-5 w-5 text-gray-400" />
                      </div>
                      <Field
                        as={Input}
                        type="password"
                        name="password"
                        placeholder="Password"
                        className={errors.password && touched.password ? 'border-red-500' : ''}
                      />
                    </div>
                    {errors.password && touched.password && (
                      <ErrorMessage message={errors.password} />
                    )}
                  </div>

                  {/* Sign Up Button */}
                  <motion.button
                    type="submit"
                    whileHover={
                      Object.keys(errors).length === 0 && Object.keys(touched).length > 0
                        ? {scale: 1.02}
                        : {}
                    }
                    whileTap={
                      Object.keys(errors).length === 0 && Object.keys(touched).length > 0
                        ? {scale: 0.98}
                        : {}
                    }
                    className={`w-full px-6 py-3 rounded-xl flex items-center justify-center transition-colors ${
                      Object.keys(errors).length > 0 || Object.keys(touched).length === 0
                        ? 'bg-gray-500 cursor-not-allowed'
                        : 'bg-orange-500 hover:bg-orange-600'
                    }`}
                    disabled={
                      Object.keys(errors).length > 0 ||
                      Object.keys(touched).length === 0 ||
                      isSubmitting
                    }
                  >
                    <span className="text-white font-medium font-inter">
                      {isSubmitting ? 'Creating Account...' : 'Sign Up'}
                    </span>
                  </motion.button>
                </Form>
              )}
            </Formik>

            {/* Sign In Link */}
            <p className="text-center text-gray-400 text-sm font-inter">
              Already have an account?{' '}
              <Link to="/login" className="text-orange-500 hover:text-orange-400 font-medium">
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </motion.div>

      {/* Image Section */}
      <motion.div
        initial={{opacity: 0, x: 20}}
        animate={{opacity: 1, x: 0}}
        transition={{duration: 1}}
        className="hidden lg:block lg:w-1/2 relative"
      >
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${images.loginSide})`,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
      </motion.div>
    </div>
  );
};

export default SignUp;
