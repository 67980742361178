import React, {createContext, useState} from 'react';
import Joyride, {ACTIONS} from 'react-joyride';
import {useNavigate} from 'react-router-dom';
import {HighlightedText, LogoText, SmallSectionHeading} from '../../components/items/Headings';
import {Container, ContentWithSlightVerticalPadding} from '../../components/items/Layouts';

const TourContext = createContext();
const ContentContainer = (heading, body) => {
  return (
    <Container>
      <SmallSectionHeading>{heading}</SmallSectionHeading>
      <ContentWithSlightVerticalPadding>{body}</ContentWithSlightVerticalPadding>
    </Container>
  );
};

const steps = [
  {
    content: ContentContainer(
      <LogoText>
        Mail<HighlightedText>Bandit</HighlightedText>
      </LogoText>,
      "Let's start by giving you a quick tour - we'll have you up and running in no time!\n"
    ),
    placement: 'center',
    target: 'body',
    pathname: '/dashboard',
  },
  {
    content: ContentContainer('Tour', 'You can come back at anytime and continue your tour here.'),
    target: '#tour-button',
    pathname: '/dashboard',
  },
  {
    content: ContentContainer(
      'Dashboard',
      <div className="text-left">
        Here you can
        <li>See your running experiments.</li>
        <li>Check each experiments results.</li>
      </div>
    ),
    target: '#dashboard-body',
    pathname: '/dashboard',
  },
  {
    target: '#create-experiment',
    content: ContentContainer(
      'Create A New Experiment',
      'Clicking here will take you to create a new experiment.'
    ),
    pathname: '/dashboard',
    placement: 'bottom',
  },
  {
    target: '#create-experiment-body',
    content: ContentContainer(
      'Create Experiment Page',
      'To create an experiment, we only need a few things from you.'
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#create-experiment-name',
    content: ContentContainer('Experiment Name', 'Give your experiment a name for your reference.'),
    pathname: '/create-experiment',
  },
  {
    target: '#create-experiment-metric',
    content: ContentContainer('Choose A Metric', 'Decide which metric you are optimizing for.'),
    pathname: '/create-experiment',
  },
  {
    target: '#create-experiment-variants',
    content: ContentContainer(
      'Template IDs',
      "Provide the Template ID's as well as the Subject Line and Preview Headers you would like to test."
    ),
    pathname: '/create-experiment',
    placement: 'bottom',
  },
  {
    target: '#create-experiment-button',
    content: ContentContainer(
      'Get Going!',
      "Click here to start the experiment. and done, that's it!"
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#header-my-account',
    content: ContentContainer(
      'Manage Account',
      'Here you can setup your subscription when you decide the time is right.'
    ),
    pathname: '/create-experiment',
  },
  {
    target: '#setup-subscription-plan',
    content: ContentContainer(
      'Subscription Plan',
      'Here you can upgrade once your free trial is over or manage existing subscriptions.'
    ),
    pathname: '/my-account',
    placement: 'top',
  },
];

const TourProvider = ({children}) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();

  const handleJoyrideCallback = async data => {
    const {status, index, type, lifecycle, action} = data;
    let nextStep = false;
    let nextStepIndex = false;

    if (status === 'skipped') {
      setRun(false);
    } else if (lifecycle === 'complete') {
      if (action === ACTIONS.NEXT) {
        nextStepIndex = index + 1;
        nextStep = steps[nextStepIndex];
      } else if (action === ACTIONS.PREV) {
        nextStepIndex = index - 1;
        nextStep = steps[nextStepIndex];
      }

      if (nextStep) {
        if (nextStep.pathname !== window.location.pathname) {
          navigate(nextStep.pathname);
        }
        setStepIndex(nextStepIndex);
      } else {
        setRun(false);
      }
    }
  };

  return (
    <TourContext.Provider value={{run, stepIndex, setRun, setStepIndex}}>
      {run && (
        <Joyride
          steps={steps}
          run={run}
          stepIndex={stepIndex}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          callback={handleJoyrideCallback}
          disableOverlayClose={true}
          scrollOffset={80}
        />
      )}
      {children}
    </TourContext.Provider>
  );
};

export {TourContext, TourProvider};
